<style scoped>
.tree_tool {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding: 5px 10px;
}

.breadcrumb {
  margin: 10px 0px;
  padding: 10px 0px;
  background-color: white;
}

.red {
  color: #ff0000;
}

.darkorange {
  color: darkorange;
}
</style>
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item>部门</el-breadcrumb-item>
          <el-breadcrumb-item>{{ cuttTreeNode && cuttTreeNode.data.name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="grid-content bg-purple">
          <div class="tree_tool">
            <el-button-group>
              <el-tooltip class="item" effect="dark" content="添加部门" placement="top">
                <el-button type="primary" icon="el-icon-circle-plus" @click="addDeptDig()" :disabled="!auth['s:d:a']"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="编辑部门" placement="top">
                <el-button type="warning" icon="el-icon-edit" @click="editDeptDig()"
                           :disabled="!auth['s:d:u']"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除部门" placement="top">
                <el-button type="danger" icon="el-icon-delete" @click="delDept()" :disabled="!auth['s:d:d']"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="刷新" placement="top">
                <el-button type="success" icon="el-icon-refresh" @click="refdept()"></el-button>
              </el-tooltip>
            </el-button-group>
          </div>
          <el-tree lazy highlight-current :expand-on-click-node="false" ref="tree"
                   node-key="id" :load="loadTreeNode" @node-click="setCuttTreeNode"
                   :props="defaultProps">
          </el-tree>
        </div>
      </el-col>
      <el-col :span="20">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item>用户数量：</el-breadcrumb-item>
          <el-breadcrumb-item>{{ page.total }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="grid-content bg-purple">
          <div class="tree_tool" style="overflow: hidden">
            <div class="fl">
              <el-button type="primary" icon="el-icon-plus" @click="addUser()" v-if="auth['s:u:a']">添加</el-button>
              <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
            </div>
            <div class="fr">
              <el-input placeholder="请输入内容" v-model="keyswords" class="input-with-select"
                        style="width:350px;">
                <el-button slot="append" icon="el-icon-search" @click="initPageData"></el-button>
              </el-input>
            </div>
          </div>
          <el-table :data="page.list" @selection-change="handleSelectionChange" v-loading="showLoading"
                    element-loading-text="拼命加载中" border style="width: 100%; height:50%">
            <el-table-column type="selection" width="35"></el-table-column>
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="角色" width="100">
              <template #default="scope"> {{ scope.row.rid}}</template>
            </el-table-column>
            <el-table-column prop="username" label="用户名"></el-table-column>
            <el-table-column prop="name" label="姓名"></el-table-column>
            <el-table-column label="性别">
              <template #default="scope"> {{ scope.row.sex == 0 ? '女' : '男' }}</template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号码"></el-table-column>
            <el-table-column prop="invalidtime" label="失效时间" width="150"></el-table-column>
            <el-table-column prop="status" label="状态" width="100">
              <template #default="scope">
                <i :class="PMSTATUS[scope.row.status].icon"
                   :style="{color:PMSTATUS[scope.row.status].color}">{{
                    PMSTATUS[scope.row.status].name
                  }}</i>
              </template>
            </el-table-column>
            <el-table-column label="操作" conter width="250">
              <template #default="scope">
                <el-button type="text" icon="el-icon-edit"
                           @click="handle_edit(scope.$index, scope.row)" v-if="auth['s:u:u']">编辑
                </el-button>
                <el-button type="text" icon="el-icon-delete" class="red"
                           @click="handle_del(scope.$index, scope.row)" v-if="auth['s:u:d']">删除
                </el-button>
                <el-button type="text" icon="iconfont iconmima2" class="darkorange"
                           @click="handle_respwd(scope.$index, scope.row)" v-if="auth['s:u:u']">重置密码
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="devlogChange" :current-page="page.pageNum"
                         :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                         :total="page.total"></el-pagination>

        </div>
      </el-col>
    </el-row>

    <!-- 部门对话框-->
    <el-dialog :title="deptInfo.digtitle" :close-on-click-modal="false" width="800px" v-model="deptdigShow">
      <el-form :model="deptInfo" ref="deptInfo_form" :rules="deptrules" class="demo-ruleForm">
        <el-form-item label="类型" label-width="100px">
          <el-radio-group v-model="deptInfo.type" :disabled="false==deptInfo.isAdd">
            <el-radio v-for="(item,index) in DEPTTYPES" :label="index" :key="item.value" v-show="index>0"
                      :disabled=" cuttTreeNode&&(cuttTreeNode.data.id!=0&&item.value<3||cuttTreeNode.data.id==0&&item.value>2) ">
              {{
                item.name
              }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="deptInfo.name"></el-input>
        </el-form-item>
        <div v-if="1===deptInfo.type||2===deptInfo.type">
          <el-form-item label="地址" label-width="100px" prop="address">
            <el-input v-model="deptInfo.address"></el-input>
          </el-form-item>
          <el-form-item label="坐标" label-width="100px"
                        prop="coordinate">
            <el-input v-model="deptInfo.coordinate" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deptdigShow=false">取 消</el-button>
        <el-button type="primary" @click="addDeptv()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 用户对话框-->
    <el-dialog :title="userInfo.digtitle" :close-on-click-modal="false" width="800px" v-model="userdigShow">
      <el-form :model="userInfo" ref="userInfo" :rules="userrules">
        <el-form-item label="姓名" label-width="100px" prop="name">
          <el-input v-model="userInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="用户名" label-width="100px" prop="username">
          <el-input :disabled="undefined!=userInfo.id" v-model="userInfo.username"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数据权限" label-width="100px" prop="ds">
              <el-select v-model="userInfo.ds" placeholder="请选择角色类型" style="width: 100%">
                <el-option v-for="item in DSS" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色权限" label-width="100px" prop="aid">
              <el-select v-model="userInfo.aid" placeholder="请选择角色类型" style="width: 100%"  @change="changerole">
                <el-option
                    v-for="(item,index) in ROLES" value-key="index"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="12">
            <el-form-item label="工号" label-width="100px" prop="posno">
              <el-input v-model="userInfo.posno"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职位:" label-width="100px" prop="posname">
              <el-input v-model="userInfo.posname"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="性别" label-width="100px">
              <el-switch v-model="userInfo.sex" active-value="0" inactive-value="1" active-color="#13ce66"
                         inactive-color="#ff4949" active-text="女" inactive-text="男"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" label-width="100px">
              <el-switch v-model="userInfo.status" active-value="1" inactive-value="0" active-text="启用"
                         inactive-text="禁用"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="undefined==userInfo.id" label="密 码" label-width="100px" prop="password">
          <el-input v-model="userInfo.password"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" label-width="100px" prop="mobile">
          <el-input v-model="userInfo.mobile"></el-input>
        </el-form-item>
        <el-form-item label="过期时间" label-width="100px" prop="invalidtime">
          <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                          v-model="userInfo.invalidtime" style="width: 100%;"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userdigShow=false">取 消</el-button>
        <el-button type="primary" @click="saveUser('userInfo')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 用户对话框-->
    <el-dialog title="设置密码" width="600px" v-model="respwddigShow" :close-on-click-modal="false">
      <el-form :model="userInfo" :rules="checkpwd" ref="pwdform">
        <el-form-item label="密 码" label-width="100px" prop="password">
          <el-input type="password" v-model="userInfo.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px" prop="checkpassword">
          <el-input type="password" v-model="userInfo.checkpassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="respwddigShow=false">取 消</el-button>
        <el-button type="primary" @click="saveUser('pwdform')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DEPT from '../../api/sys/api_dept';
import USER from '../../api/base/api_user';
import ROLE from '../../api/sys/api_role';


export default {
  name: "promanage",
  data() {
    let validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入详细地址'));
      } else {
        var _this = this;
        var geocoder = new BMap.Geocoder();
        geocoder.getPoint(this.deptInfo.address, function (point) {
          if (point) {
            var mark = new BMap.Marker(point);
            mark.enableDragging();
            _this.deptInfo.coordinate = '[' + point.lng + ',' + point.lat + ']';
            _this.MAP.centerAndZoom(point, 16);
            _this.MAP.addOverlay(mark);
            callback();
          } else {
            callback(new Error('当前地址不存在！请输入正确的详细地址！'));
          }
        });

      }
    };
    let validateUserName = (rule, value, callback) => {
      if (value != '' && value.length > 2) {
        if (this.userInfo.id && this.userInfo.id > 0) {
          callback();
        }
        USER.extUserName({userName: value}).then((res) => {
          if (200 === res.code) {
            callback();
          } else {
            callback(new Error('用户名已经被使用了！'));
          }
        });
      } else {
        callback(new Error('用户名长度在 3 到 15 个字符，由字母和数字组成！'));
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      auth: {},
      //dept-->tree
      cuttTreeNode: null,
      deptdigShow: false,
      PMSTATUS: {
        '-1': {name: "过期", icon: 'el-icon-circle-close', color: '#E6A23C'},
        '0': {name: "禁用", icon: 'el-icon-circle-close', color: '#DC143C'},
        '1': {name: "激活", icon: 'el-icon-circle-check', color: '#30d351'},
      },
      DEPTTYPES:
          [
            {value: 0, name: '系统', icon: 'el-icon-s-home', color: '#808080'},
            {value: 1, name: '集团', icon: 'el-icon-school', color: '#FF4500'},
            {value: 2, name: '服务站', icon: 'iconfont bigicon iconweixiu1', color: '#FFD700'},
            {value: 3, name: '组织', icon: 'iconfont bigicon iconweixiu1', color: '#FFD700'},
          ],
      DSS: [],

      deptInfo: {digtitle: "添加部门信息", type: 1, name: null, address: null, coordinate: null},
      defaultProps: {children: 'zones', label: 'name', isLeaf: 'leaf'},
      //user-->列表
      keyswords: '',
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      //user-->gig
      userdigShow: false,
      userInfo: {},
      respwddigShow: false,
      ROLES: {},
      roleOptions: [],
      deptrules: {
        name: [{required: true, message: '请输入集团或者部门名称', trigger: 'blur'}, {
          min: 2,
          max: 25,
          message: '长度在 2 到 25 个字符',
          trigger: 'blur'
        }],
        address: [{required: true, trigger: 'blur'}],
        coordinate: [{required: false, message: '请输入集团坐标', trigger: 'blur'}],
      },
      checkpwd: {
        password: [{
          required: true,
          pattern: /^[a-z+A-Z+0-9+]{3,15}$/,
          message: '请输入用户密码！',
          trigger: 'blur'
        }, {min: 6, max: 15, message: '长度6~25位，包括至少1个大写字母,1个小写字母，1个数字，1个特殊字符', trigger: 'blur'}],
        checkpassword: [{required: true, validator: validatePass2, trigger: 'blur'}]
      },
      userrules: {
        ds: [{required: true, message: '请选择用户的操作数据范围', trigger: 'blur'}],
        aid: [{required: true, message: '请选择用户的授权类型', trigger: 'blur'}],
        name: [{required: true, message: '请输入用户的真实姓名', trigger: 'blur'}, {
          min: 2,
          max: 15,
          message: '长度在 2 到 15 个字符',
          trigger: 'blur'
        }],
        username: [{required: true, message: '请输入用户名,长度在 3 到 15 个字符，由字母和数字组成！', trigger: 'blur'}, {
          min: 3,
          max: 15,
          message: '长度在 3 到 15 个字符',
          trigger: 'blur'
        }, {validator: validateUserName, trigger: 'blur'}],
        password: [{
          required: true,
          pattern: /^[a-z+A-Z+0-9+]{3,15}$/,
          message: '请输入用户密码！',
          trigger: 'blur'
        }, {min: 6, max: 15, message: '长度6~25位，包括至少1个大写字母,1个小写字母，1个数字，1个特殊字符', trigger: 'blur'}],
        mobile: [{required: false, pattern: /^1\d{10}$/, message: '请输入正确的手机号码！', trigger: 'blur'}],

        invalidtime: [{required: false, message: '请设置失效的时间！', trigger: 'blur'}],
      }
    }
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.$refs.tree.setCheckedKeys([this.$store.state.cuttuser.did]);
      this.init_base();
      this.init_role();
      this.initPageData();
    },
    init_base() {
      ROLE.datascope().then(res => {
        this.DSS = res.code === 200 ? res.data : [];
      })
    },
    async init_role() {
      let res = await ROLE.getRoleList({pageNum: 1, pageSize: 100})
      if (res.code == 200) {
        let rs = {}, data =res.pageData.list;
        for (let r of data) {
          rs[r.id] = r;
        }
        this.ROLES = rs;
      }
    },
    //========================================================================集团&&部门信息========================================================================
    //动态加载树节点
    async loadTreeNode(node, resolve) {
      if (node.level === 0) {
        let data = await DEPT.getRootNode()
        return resolve(data);
      }
      if (node.data && (node.data.isSelect || node.data.leaf)) {
        return resolve([]);
      }
      DEPT.getUserTree({id: node.data.id, isLoadUser: false}).then((data) => {
        return resolve(data);
      });
    },
    setCuttTreeNode(data, node, e) {
      if (this.cuttTreeNode && node.data.id == this.cuttTreeNode.data.id) {
        return;
      }
      this.page.pageNum = 1;
      this.cuttTreeNode = node;
      this.initPageData();
    },
    addDeptDig() {
      if (this.cuttTreeNode == null ) {
        return;
      }
      let parentNode = this.cuttTreeNode.data;
      this.deptInfo = {
        digtitle: '添加组织信息',
        name: undefined,
        pid: parentNode.id,
        type: this.cuttTreeNode.data.id == 0 ? 1 : 3,
        isAdd: true
      };
      this.initMap();
      this.deptdigShow = true;
    },
    editDeptDig() {
      if (this.cuttTreeNode == null || this.cuttTreeNode.data.id == 0) {
        this.$message.error("请选择节点信息进行编辑！");
        return;
      }
      let nodedata = {...this.cuttTreeNode.data};
      nodedata.isAdd = false;
      nodedata.address = nodedata.address ? nodedata.address : nodedata?.other
      nodedata.digtitle = '修改' + this.DEPTTYPES[nodedata.type].name + '信息';
      this.deptInfo = nodedata;
      this.initMap(nodedata.coordinate);
      this.deptdigShow = true;
    },
    addDeptv() {
      this.$refs['deptInfo_form'].validate((valid) => {
        if (valid) {
          this.addDeptData();
        } else {
          return false;
        }
      });
    },
    async addDeptData() {
      let res = await (undefined != this.deptInfo?.id ? DEPT.update(this.deptInfo) : DEPT.add(this.deptInfo));
      if (res && 200 === res.code) {
        this.deptdigShow = false;
        //this.cuttTreeNode.data=res.data;
        if (this.deptInfo.id) {
          this.cuttTreeNode.parent.loaded = false
          this.cuttTreeNode.parent.expand();
        } else {
          this.cuttTreeNode.loaded = false;
          this.cuttTreeNode.expand();
        }
        this.$message.success("操作成功！");
      } else {
        this.$message.error(res.msg);
      }
    },

    delDept() {
      if (this.cuttTreeNode == null) {
        this.$message.error("请选择需要操作的集团或者部门信息！");
        return;
      }
      if (this.cuttTreeNode.id == 0) {
        this.$message.error("跟节点不能操作！");
        return;
      }
      if (this.cuttTreeNode.childNodes.length > 0 || this.cuttTreeNode.data.type == 0) {
        this.$message.error("当前节点下存在子节点或当前节点不能操作！");
        return;
      }
      this.$confirm('此操作将删除[' + this.cuttTreeNode.data.name + '] 部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DEPT.delete({id: this.cuttTreeNode.data.id}).then((res) => {
          if (res && 200 === res.code) {
            this.deptdigShow = false;
            this.cuttTreeNode.parent.loaded = false
            this.cuttTreeNode.parent.expand()
            this.cuttTreeNode = null;
            this.$message.success("操作成功！");
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },
    refdept() {
      if (this.cuttTreeNode) {
        this.cuttTreeNode.childNodes = [];
        DEPT.getUserTree({id: this.cuttTreeNode.data.id, isLoadUser: false}).then((data) => {
          this.cuttTreeNode.doCreateChildren(data)
        });
      }
    },

    initMap(coordinate) {
      if (this.deptInfo.type > 2) {
        return
      }
      setTimeout(() => {
        //   let map = null;
        //   if (undefined == this.MAP) {
        //     map = new BMap.Map("addressmap");
        //     map.centerAndZoom(new BMap.Point(116.404, 39.915), 24);
        //     map.enableScrollWheelZoom();
        //     this.MAP = map;
        //   }
        //   if (coordinate) {
        //     let p = JSON.parse(coordinate), point = new BMap.Point(p[0], p[1]), mk = new BMap.Marker(point);
        //     mk.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
        //     this.MAP.addOverlay(mk);
        //     this.MAP.panTo(point);
        //   } else {
        //     let geolocation = new BMap.Geolocation();
        //     geolocation.getCurrentPosition(function (r) {
        //       if (this.getStatus() == BMAP_STATUS_SUCCESS) {
        //         let mk = new BMap.Marker(r.point);
        //         mk.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
        //         this.MAP.addOverlay(mk);
        //         this.MAP.panTo(r.point);
        //       } else {
        //       }
        //     }, {enableHighAccuracy: true})
        //   }
      }, 100);
    },

    //========================================================================用户信息========================================================================
    handleSelectionChange(val) {

    },
    changerole(val) {
      this.userInfo.rid = this.ROLES[val].type;
    },

    initPageData() {
      this.showLoading = true;
      USER.getUserList({
        deptId: this.cuttTreeNode ? this.cuttTreeNode.data.id : this.$store.state.cuttuser.did,
        key: this.keyswords,
        type: null,
        status: null,
        pageNum: this.page.pageNum, pageSize: this.page.pageSize,
      }).then((res) => {//初始化项目设备
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });

    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    addUser() {
      if (this.cuttTreeNode == null) {
        this.$message.error("请选择一个集团或者部门信息添加用户！");
        return;
      }
      this.userInfo = {
        digtitle: '添加用户信息',
        sex: "1",
        status: "1",
        I: 0,
        type: undefined,
        rid: undefined,
        aid:undefined,
        did: this.cuttTreeNode.data.id
      };
      this.userdigShow = true;
    },
    saveUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          USER.add(this.userInfo).then((res) => {
            if (200 == res.code) {
              this.$message.success("操作成功！");
              this.userdigShow = false;
              this.respwddigShow = false;
              this.initPageData();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handle_edit(index, row) {
      this.userInfo = {...row};
      this.userInfo.digtitle = '编辑用户信息';
      this.userInfo.I = 1;
      this.userInfo.sex = row.sex + '';
      this.userInfo.status = row.status + '';
      this.userdigShow = true;
    },
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        USER.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },
    handle_respwd(index, row) {
      this.userInfo = {id: row.id, I: 2, username: row.username};
      this.respwddigShow = true;
    },
  },
  mounted() {
    this.init();
  }
}
</script>


